"use strict";

export default class Carousel {
	 
	 
	constructor(options) {
		const defaults = {
			selectorSlider: '#slider',
			selectorSlides: '.slide',
			slideDuration: 5000,
			transitionDuration: 1200,
			
			sliderReadyClassName: 'slider-ready',
			
			slideActiveClassName: 'slide-active',
			slideExitClassName: 'slide-active-off',
			
			bullets: true,
			bulletsNavClassName: 'slides--bullets',
			bulletClassName: 'slide--bullet',
			bulletActiveClassName: 'slide--bullet-active',
			
			showProgress: true,
		};
		
		this.options = Object.assign(defaults, options);
		
		this.slider = document.querySelector(this.options.selectorSlider);
		if(! this.slider) {
			return;
		}
		
		this.slides = this.slider.querySelectorAll(this.options.selectorSlides);
		if( this.slides.length === 0 ) {
			return;
		}
		
		this.timerId = null;
		this.interTimerId = null,
		this.classNameTimerId = null,
		this.index = null,
		
		this.initialize();
	}


	initialize() {
		this.setupNav();
		this.setupProgressBar();
		this.show(0);
	}


	setupNav() {
		if( this.slides.length < 2 || ! this.options.bullets ) {
			return;
		}
			
		const _this = this;
		const nav = document.createElement('ul');
		
		nav.classList.add( this.options.bulletsNavClassName );
		
		this.slides.forEach( (s, i) => { 
			const a = document.createElement('a'),
				li = document.createElement('li');
				
			a.setAttribute('data-slide', i);
			a.classList.add(this.options.bulletClassName, this.options.bulletClassName +'-'+ i);
			a.addEventListener('click', event => {
				event.preventDefault();
				const index = parseInt(event.currentTarget.dataset.slide, 10);
				_this.show(index);
			});
			
			li.appendChild(a);
			nav.appendChild(li);
		});
		
		this.slider.appendChild(nav);
	}
	
	
	updateNav() {
		if( this.slides.length < 2 || ! this.options.bullets ) {
			return;
		}
			
		const _this = this;
		const bullets = this.slider.querySelectorAll('.'+ _this.options.bulletActiveClassName);
		bullets.forEach(b => { b.classList.remove(_this.options.bulletActiveClassName) });

		this.slider.querySelectorAll('.'+ this.options.bulletsNavClassName +' a')[this.index].classList.add(_this.options.bulletActiveClassName);
	}
	
	
	setupProgressBar() {
		if((! this.options.showProgress) || this.slides.length < 2) {
			return;
		}
		
		this.progressBar = document.createElement('div');
		this.progressBar.classList.add('progress-bar');
		this.slider.appendChild(this.progressBar);
	}
	
	show(index) {
		const _this = this;
		
		clearInterval(this.timerId);
		clearInterval(this.interTimerId);
		clearInterval(this.classNameTimerId);

		if(! this.slides[index] ||this.slides[index].classList.contains(this.options.slideActiveClassName))
			return;
		
		this.slides.forEach( (s, i) => {
			if(! s.classList.contains(_this.options.slideActiveClassName))
				return;
			
			s.classList.remove(_this.options.slideActiveClassName);
			s.classList.add(_this.options.slideExitClassName);
			
			_this.resetProgressBar();
			
			_this.classNameTimerId = setInterval(() => {
				_this.slides.forEach( _s => {
					_s.classList.remove(_this.options.slideExitClassName);
				});
				clearInterval(_this.classNameTimerId);
			}, _this.options.transitionDuration);
		});


		this.index = index;


		this.interTimerId = setInterval(() => {
			_this.updateNav();
			_this.slides[index].classList.add(_this.options.slideActiveClassName);
			clearInterval(_this.interTimerId);
			_this.resume();
		}, _this.options.transitionDuration);
	}
	
	
	resetProgressBar() {
		if(! this.progressBar )
			return;
		this.progressBar.style.transition = '0ms';
		this.progressBar.style.width = 0;
	}

	animateProgressBar() {
		if(! this.progressBar )
			return;
		
		this.progressBar.style.width = '100%';
		this.progressBar.style.transition = 'width '+ this.options.slideDuration +'ms linear';
	}


	resume() {
		const _this = this;
		
		this.animateProgressBar();
		
		this.timerId = setInterval(() => {
			_this.next();
		}, this.options.slideDuration);
	};


	


	next() {
		if( this.index === this.slides.length - 1 )
			return this.show(0);

		this.show(this.index + 1);
	}
}