"use strict";

import Carousel from './classes/Carousel';

(function() {
	new Carousel({
		selectorSlider: '.app-slider',
		selectorSlides: '.slider--slide',
		slideDuration: 9000,
		transitionDuration: 600,
		showProgress: false,
		bullets: false,
	});
})();